import { Fragment } from 'react';

import PostEmailSignup from './email-signup';
import PostRelatedPosts from './related-posts';

const PostGlobals = () => (
    <Fragment>
      <PostEmailSignup  />
      <PostRelatedPosts />
    </Fragment>
);

export default PostGlobals;