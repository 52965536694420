import { graphql, useStaticQuery } from 'gatsby';

import PostCard from 'components/posts/post-card';

import '../../styles/post-related-posts.scss';

const PostRelatedPosts = () => {
  const data = useStaticQuery(graphql`
  {
    wordpressAcfOptions {
      options {
        blog_post_related_posts {
          post_one {
            post_url {
              title
              url
              target
            }
            post_featured_image {
              source_url
            }
            post_title
            post_category
          }
          post_two {
            post_url {
              title
              url
              target
            }
            post_featured_image {
              source_url
            }
            post_title
            post_category
          }
          post_three {
            post_url {
              title
              url
              target
            }
            post_featured_image {
              source_url
            }
            post_title
            post_category
          }
          post_four {
            post_url {
              title
              url
              target
            }
            post_featured_image {
              source_url
            }
            post_title
            post_category
          }
          post_five {
            post_url {
              title
              url
              target
            }
            post_featured_image {
              source_url
            }
            post_title
            post_category
          }
          post_six {
            post_url {
              title
              url
              target
            }
            post_featured_image {
              source_url
            }
            post_title
            post_category
          }
        }
      }
    }
  }
`);

  const pageContext = data.wordpressAcfOptions.options.blog_post_related_posts;

  return (
    <section
      className="post-related-posts-section section"
      data-section="related posts section"
    >
      <div className="container">
        <div className="columns is-variable is-5">
          {Object.values(pageContext).map(
            (related_post: any, index: number) => {
              const card = {
                slug: related_post.post_url.url,
                source_url: related_post.post_featured_image.source_url,
                name: related_post.post_category,
                title: related_post.post_title,
              };
              return <PostCard
                key={ index }
                card={ card }
              />;
            }
          )}
        </div>
      </div>
    </section>
  ); 
};

export default PostRelatedPosts;