import { graphql, useStaticQuery } from 'gatsby';

import Vero from 'components/forms/vero';

import '../../styles/post-email-signup.scss';

const PostEmailSignup = () => {
  const data = useStaticQuery(graphql`
      {
        wordpressAcfOptions {
          options {
            blog_post_email_signup {
              headline
              sub_head
              left_image {
                source_url
              }
              right_image {
                source_url
              }
            }
          }
        }
      }
    `);

  const pageContext = data.wordpressAcfOptions.options.blog_post_email_signup;

  return (
    <section className="post-email-signup-section section">
      <div className="container">
        <img loading="lazy" src={ pageContext.left_image.source_url } role="presentation" />
        <div className="form-container">
          <h2 className="title is-size-2-mobile">{pageContext.headline}</h2>
          <p className="sub-title">{pageContext.sub_head}</p>
          <Vero
            formType="blogSubscription"
            emailField
            uniqueId="veroSubscribe"
            submitBtnText="Sign Me Up!"
          />
        </div>
        <img loading="lazy" src={ pageContext.right_image.source_url } role="presentation" />
      </div>
    </section>
  );
};

export default PostEmailSignup;