import React, { createElement } from 'react';
import HtmlToReact from 'html-to-react';

import Link from 'components/link/link';

import './styles/post-content.scss';
import InlineCTA from './inline-cta';
const HtmlToReactParser = new HtmlToReact.Parser();


const FormatContent = html => {
  const updatedHTML = html.replace(/\{\{(\d*)\}\}/g, (_, id) => `<div class="wiw-post-content" data-cta-id="${id}"></div>`);

  return updatedHTML;
};

const PostContent = props => {
  //replace shortcode in string with parseable entity so we can ID it later and replace with the inline_cta component and Links too!
  const html = FormatContent(props.content);

  //load up general node defs
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);

  const processingInstructions = [
    //inline CTA conversion
    {
      replaceChildren: true,
      shouldProcessNode: function (node) {
        return node.attribs && node.attribs['class'] === 'wiw-post-content';
      },
      // eslint-disable-next-line react/display-name
      processNode: (node, children, index) => {
        return createElement(
          InlineCTA,
          { id: node.attribs['data-cta-id'], key: index },
          children
        );
      },
    },
    //Link conversion
    {
      shouldProcessNode: node => {
        return node && node.name && node.name === 'a';
      },
      // eslint-disable-next-line react/display-name
      processNode: (node, children, index) => {
        if (node.attribs['target'] === '_blank') {
          return createElement(
            'a',
            { href: node.attribs['href'], target: '_blank', rel: 'noopener noreferrer' },
            children
          );
        } else {
          return createElement(
            Link,
            { to: node.attribs['href'], key: index },
            children
          );
        }

      },
    },
    {
      // Anything else, process as normal html element
      shouldProcessNode: () => {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  const htmlComponents = HtmlToReactParser.parseWithInstructions(html, () => {
    return true;
  },
  processingInstructions
  );

  return <div className="post-content-container">{htmlComponents}</div>;
};

export default PostContent;
